<template>
  <static-fullscreen-card>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <template v-slot:title> ({{ data.id }}) {{ `${data.contract_number || "Без номера"}` }} [{{ statusCurrent.text || "" }}] </template>
    <template v-slot:actions>
      <a-btn-send-code v-if="id && data.status === 0 && false" :id="id" :api="api" @success="fetchData(id)" :title="'Подпись(SMS)'" />
      <v-btn v-if="data.status == 2 && data.print_act" :href="$root.config.fileUrl + data.print_act" target="_blank" class="mx-1" dark color="info" title="Скачать">
        <v-icon dark>fas fa-file-signature </v-icon>
      </v-btn>
      <v-btn v-else-if="data.id" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="Предпросмотр">
        <v-icon dark>fas fa-eye </v-icon>
      </v-btn>
      <a-btn-status v-if="getAccess('contract.status', { users: [data.createdby_id] })" :items="statusItems" @click="changeStatus($event)" :disabled="canStatus" />
      <a-btn-edit v-if="accessEdit && data.status === 0" @click="showEditDialog = true" :disabled1="!canEdit" />
      <a-btn-delete
        v-if="id && getAccess('contract.delete', { users: [data.createdby_id] }) && (data.status || 0) === 0"
        :disabled="!canDelete"
        @click="removeDialogShow = true"
      />
    </template>
    <v-row>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6" class="px-2">
        <material-card color="secondary">
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="12" class="px-2 pt-1">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <v-card-text v-if="t.name == 'files'" :style="styleDetailCard" class="pa-0">
                <v-fab-transition v-if="data.status === 0">
                  <v-btn fab dark color="green" absolute bottom small style="bottom: 25px" left class="v-btn--example" @click="addNewDocs('docs')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
                <a-view-filesdata :value="data.filesData" />
              </v-card-text>
              <s-tabs-status-history v-if="t.name == 'statusHistory'" :data="data.status_history" :m="m" :height="detailHeight" />
              <v-card-text v-if="t.name == 'appendix'" :style="`height: ${detailHeight}px; overflow: auto`" class="pa-0">
                <TabAppendix :data="appendixTable" @add="appendixAdd()" @edit="appendixEdit($event)" @delete="appendixDelete($event)" />
              </v-card-text>
              <v-card-text v-if="t.name == 'comments'" :style="`height: ${detailHeight}px; overflow: auto`">
                <comment-view :id="id || 0" :targetName="'contract'" :needUpdate="needUpdateComments" :height="detailHeight" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
    <div v-if="loading" class="loading-block">
      <div class="loader"></div>
    </div>
    <portal to="v-main">
      <s-document-head v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :config="configHead" :title="m.title" :id="id" />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <s-document-dialogs-detail-edit
        v-if="showAppendixEditDialog"
        v-model="showAppendixEditDialog"
        :id="idEdit"
        :dataTable="appendixTable"
        :readonly="data.status == 2"
        :RO="fieldsRO"
        :m="m"
        type="appendix"
        @save="appendixTable = $event"
        @refresh="fitchData()"
      >
        <template v-slot:form="{ data }">
          <div v-if="data.config && data.config.type == 'object-techrequirements'">
            <v-btn @click="getAppendixData(data.config, data)">Взять из объекта</v-btn>
            <a-input-object-techrequirements :value="data.data" :model="{ name: 'data', title: 'Техническое задание', type: 'object-techrequirements' }" />
          </div>
          <div v-if="data.config && data.config.type == 'object-gallery'">
            <v-btn @click="getAppendixData(data.config, data)">Взять из объекта</v-btn>
            <a-input-object-gallery v-model="data.data" :model="{ name: 'data', title: 'Галерея', type: 'object-gallery', video: false }" />
          </div>
          <div v-if="data.config && data.config.type == 'object-schedule'">
            <v-btn @click="getAppendixData(data.config, data)">Взять из объекта</v-btn>
            <a-input-object-schedule v-model="data.data" />
          </div>
        </template>
      </s-document-dialogs-detail-edit>
    </portal>
    <confirm-dialog ref="confirmDialog" />
    <a-loader v-if="loading" />
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, validate } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, validate],
  props: {
    idShow: Number,
  },
  components: {
    TabAppendix: () => import("./tabs/tabContractAppendix"),
  },
  data() {
    return {
      needUpdateComments: 0,
      id: 0,
      idEdit: 0,
      idDeleting: 0,
      fieldsRO: [],
      fab: false,
      suEditShow: false,
      showEditDialog: false,
      showAppendixEditDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить документ?",
      showAddDocDialog: false,
      addDocModel: null,
      loading: false,
      loaded: false,
      title: "",
      data: {},
      m: this.$store.getters["config/get"].models.contracts,
      tab: 0,
      tabs: [
        { name: "files", title: "Документы", show: true },
        { name: "appendix", title: "Приложения", show: true },
        { name: "comments", title: "Комментарии", show: this.getAccess(`contract.comments`, null, true) },
        { name: "statusHistory", title: "История статусов", show: this.getAccess("contract.statusHistory") },
      ],
    };
  },
  created() {
    this.loading = true;

    this.$root.title = this.m.title;
    this.id = this.idShow || Number(this.$route.params.id);
    //    this.permit = this.getAccess("menu.objects");
    this.fitchData();
    this.loaded = true;
  },
  mounted() {},

  computed: {
    accDocName() {
      return this.m.accDocName;
    },
    api() {
      return this.m.api;
    },
    url() {
      return this.m.api;
    },

    accessEdit() {
      return this.getAccess("contract.edit", { users: [this.data.createdby_id] });
    },

    canEdit() {
      return true;
    },
    canDelete() {
      return true;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    configHead() {
      return this.m.config.default || {};
    },
    model: {
      get() {
        let conf = JSON.parse(JSON.stringify(this.m?.config || {}));
        const f = conf?.watch;
        let config;
        if (f) {
          config = conf?.[this.data[f]];
        }
        config = config || conf.default;
        // this.configHead = config;
        let model = this.calcModelConfig(config);
        console.log("model", config, model);
        return model;
      },
    },
    appendixTable: {
      get() {
        let res = [...(this.data.template_data || [])];
        res.forEach((r, i) => (r.id = i + 1));
        return res;
      },
      set(v) {
        let res = [...v];
        res.forEach((r) => delete r.id);
        this.editAppendixAction(res);
      },
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showDialogEditDetail(v) {
      if (!v) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    getPreview() {
      return this.$root.config.baseUrl + `/accounting/dir/contract/print_doc/${this.id}?token=` + localStorage.getItem("user-token");
    },
    async getAppendixData(config, data) {
      if (config.type == "object-techrequirements") {
        if (!this.data.object_id) return;
        let resp = await this.$axios.get("/mechti/objects/" + this.data.object_id);
        data.data = resp.data.data[config.field];
      }
      if (config.type == "object-gallery") {
        if (!this.data.object_id) return;
        let resp = await this.$axios.get("/mechti/objects/" + this.data.object_id);
        data.data = resp.data.data[config.field];
      }
      if (config.type == "object-schedule") {
        if (!this.data.object_id) return;
        let resp = await this.$axios.get("/mechti/construction-schedule", { params: { filters: { object_id: this.data.object_id } } });
        data.data = resp.data.data;
      }
    },

    async editAppendixAction(d) {
      const api = this.url;
      const template_data = d;
      const data = { id: this.id, template_data };
      let response = await this.$axios.post(api, data);
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Данные сохранены",
        });
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка изменения данных",
        });
      }
      await this.fitchData();
    },
    async beforeChangeStatus(status) {
      if ([1, 2].includes(status.value)) {
        return await this.validateData(this.data, this.model, true);
      }
      return true;
    },

    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field);
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },

    getDetailsModel(n) {
      return this.m["list" + n.upFirst()];
    },

    async fitchData() {
      this.loading = true;
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetchData();
      this.loading = false;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      if (this.idShow) this.$emit("close", this.data);
      else this.$router.push({ name: "contracts" });
    },
    afterFetchData(r) {
      if (this.id) {
        //   this.calcModel();
      }
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },

    onClickRow(e, d = {}) {
      if (e.field?.isMenu) {
        let name = e.field?.alias || e.field.name;
        let id = e.row?.[e.field.name];
        if (id) this.showPopupMenu(name, e.row, e.event, { task: { id: e.row[e.field.name] } });
      } else if (e.field.name == "actions") {
      } else {
        if (["rooms", "works", "goods"].includes(d.name)) {
          this.idEdit = e.row.id;
          this.detailIndex = d.detailIndex;
          this.detailName = d.name;
          this.fieldsRO = [];
          this.showDialogEditDetail = true;
          return;
        }
      }
    },
    appendixAdd(e) {
      this.idEdit = 0;
      this.showAppendixEditDialog = true;
    },
    appendixEdit(e) {
      this.idEdit = e.key + 1;
      this.showAppendixEditDialog = true;
    },
    appendixDelete(e) {
      return this.editAppendixAction(e);
    },
  },
};
</script>
